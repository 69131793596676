body.register-wizard {
    button[class^="account-"],
    button[class^="bank-"],
    button[class^="payment-"],
    button.bookmarker-next,
    a[class^="account-"],
    a[class^="bank-"],
    a[class^="payment-"] {
        display: block;
        text-align: center;
        background-color: #575757;
        border-color: #575757;
        color: #fff;
        border-radius: 3px;

        border: 1px solid transparent;
        padding: 0.45rem 0.9rem;
        font-size: 16px;
        line-height: 1.5;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
            box-shadow 0.15s ease-in-out;
        box-shadow: 0 2px 6px 0 #57575780;
        min-width: 120px;
        font-weight: 600;

        &.disabled,
        &:disabled {
            opacity: 0.4;
            cursor: default;
        }
    }

    button[class*="-next"],
    button[class*="-add"],
    a[class*="-next"],
    a.account-verify-request,
    button.account-verify-request,
    a.account-tos-accept {
        background-color: #009ef7;
        border-color: #009ef7;
        color: #fff;
    }

    a.kyc-passed,
    button.kyc-passed,
    a#kyc-failed,
    button#kyc-failed,
    a#account-verify-request,
    button#account-verify-request{
        display: block;
        text-align: center;
        background-color: #575757;
        border-color: #575757;
        color: #fff;
        border-radius: 3px;

        border: 1px solid transparent;
        padding: 0.45rem 0.9rem;
        font-size: 16px;
        line-height: 1.5;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
            box-shadow 0.15s ease-in-out;
        box-shadow: 0 2px 6px 0 #57575780;
        min-width: 120px;
        font-weight: 600;

        &.disabled,
        &:disabled {
            opacity: 0.4;
            cursor: default;
        }

        background-color: #009ef7;
        border-color: #009ef7;
        color: #fff;
    }    

    button.bank-acct-connect-plaid {
        background-color: #f8c761;
        border-color: #f8c761;
        color: #000;
    }

    @media (max-width: 767px) {
        button[class^="account-"],
        button[class^="bank-"],
        a[class^="account-"] {
            padding: 0.45rem 0.9rem;
            min-width: 100px;
            width: 100%;
            border-radius: 30px;
            font-size: 18px;

            margin-bottom: 18px;
            height: 50px;
            border-radius: 30px;
            font-size: 18px;
        }
    }

    // button {
    //     &.account-name-next,
    //     &.account-dob-next,
    //     &.account-phone-next,
    //     &.account-ssn-next,
    //     &.account-address-next,
    //     &.account-verify-request,
    //     &.account-pending-next,
    //     &.account-card-next,
    //     &.account-tos-accept,
    //     &.bank-acct-connect-plaid,
    //     &.bank-acct-next,
    //     &.payment-method-add,
    //     &.payment-method-cancel,
    //     &.payment-method-list-next,
    //     &.bookmarker-next,
    //     &.verify-email-resend-link {
    //         background-color: #009ef7;
    //         border-color: #009ef7;
    //         color: #fff;
    //     }
    // }
}
