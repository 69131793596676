//
// _responsive.scss
//

.account-pages {
    display: block;
}

// Auth layout
.authentication-bg {
    .footer {
        width: 100%;
        left: 0;
        background: transparent;
        border: none;
    }

    .legal-document {
        margin: 60px;
        .legal-frame {
            min-height: calc(100vh - 240px);
        }
    }
}

.legal-document {
    position: relative;
    & > div{
        max-width: 1200px;
        margin: auto;
    }
    .bg-black {
        display: none;
        &.eb_header{
            display: block;
            img{
                width: auto;
            }

            .right-menu{
                .legal-signin{
                    display: block;
                }
            }

            .form-logo{
                position: relative;
                .legal-back{
                    display: none;
                    color: #f8c761;
                    font-size: 28px;
                    line-height: 22px;
                    margin-right: 10px;
                }
            }
        }
    }

    .right-menu{
        .legal-signin{
            position: absolute;
            display: none;
            right: 0;
            top: -40px;
            z-index: 1000;
            padding: 6px 0 !important;
            color: #f8c761;
            background-color: #141414;
            border: 1px solid #141414;
            min-width: 48px;
            font-size: 28px;
            line-height: 22px;
        }
    }
}

.tab_drawer_heading {
    display: none;
}

.card-header {
    padding: 1.5rem 1.5rem 1rem 1.5rem;
}

.repay-early-box {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .select-payment-method {
        max-width: 250px;
        display: inline-block;
        margin-right: 20px;
        text-transform: capitalize;
    }

    span {
        display: inline-block;
    }
}

.vidoe-container {
    position: relative;
}

.mobile-pdf-reader {
    display: none;
}

.mobile-show {
    display: none !important;
}

@media (max-width: 576px) {
    .footer{
        display: none;
    }

    .mobile-show {
        display: block !important;
    }
    
    .mobile-hide {
        display: none !important;
    }
    
    // Auth Pages
    .account-pages {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;

        h4 {
            font-size: 20px;
            font-weight: 500;
            line-height: 28px;
        }

        label {
            font-weight: 400;
        }

        .form-group {
            margin-bottom: 1.5rem;
        }

        .form-control {
            font-size: 16px;
            padding: 8px 25px 8px 15px;
            height: auto;
        }
    }

    // Auth layout
    .authentication-bg {
        .legal-document {
            margin: 10px;
            .bg-black {
                &.eb_header{
                    .right-menu {
                        .legal-signin{
                            display: none;
                        }
                    }

                    .form-logo {
                        padding: 0 20px 0 0px;
                        display: flex;
                        align-items: center;
                        margin-left: -10px;

                        .legal-back{
                            display: block;
                        }
                    }
                }
            }
        }
    }

    .edge-tab {
        display: none;
    }

    .edge-tab-content {
        box-shadow: 0px 11px 30px rgba(0, 0, 0, 0.04);
        border-radius: 3px;
    }

    .legal-document {
        .bg-black {
            &.eb_header{
                display: block;
                img{
                    width: 100%;
                }
            }    
        }
    }

    .tab_drawer_heading {
        border-top: 1px solid #f6f6f6;
        margin: 0;
        padding: 5px 30px;
        display: block;
        cursor: pointer;
        color: #000;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        position: relative;

        font-size: 18px;
        font-weight: 500;
        line-height: 38px;

        &:first-child {
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
            border-top: none;
        }

        &:last-child {
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;
        }

        &::after {
            position: absolute;
            content: "";
            border-style: solid;
            border-color: initial;
            transform: rotate(-45deg) translateY(-50%);
            transform-origin: top;
            top: 50%;
            transition: all 0.3s ease-out;
            width: 0.6em;
            height: 0.6em;
            right: 1.5em;
            border-width: 0 1px 1px 0;
        }

        &.tab-active {
            background-color: #f5f5f5;
            font-weight: 600;

            &::after {
                transform: rotate(45deg) translateY(-50%);
            }
        }
    }

    .left-side-menu {
        padding-top: 180px;
    }

    .content-page {
        padding: 180px 12px;
    }

    .card-title {
        font-size: 18px;
        font-weight: 600;
        line-height: 21px;
    }

    .card-header {
        padding: 1rem 1.5rem;
    }

    .side-nav {
        .side-nav-link,
        .side-sub-nav-link {
            font-size: 14px;
            font-weight: 600;
        }
    }

    .inside-header {
        padding-left: .5rem;
        padding-right: 1.5rem;

        button {
            font-size: 9px;
            font-weight: 600;
        }
    }

    .mobile-full-body {
        padding-left: 0;
        padding-right: 0;
    }

    .total-deposit-label {
        font-size: 18px;
        font-weight: 600;
    }

    .total-deposit {
        font-size: 36px;
        font-weight: 600;
    }

    .available-label {
        font-size: 12px;
        font-weight: 400;
        line-height: 21px;
    }

    .available-boost-amount {
        span {
            background: #2ca9f2;
            width: 100%;
            height: 100%;
            display: flex;
            border-radius: 3px;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            font-weight: 700;
            line-height: 21px;
        }
    }

    .deposit-box {
        .btn-sm {
            font-size: 12px;
            font-weight: 600;
        }
    }

    .stat-panel {
        padding: 30px;
        border-bottom: 1px solid #f4f4f4;
        &:first-child {
            padding-top: 20px;
        }
        &:last-child {
            padding-bottom: 20px;
            border-bottom: none;
        }

        h4 {
            font-size: 18px;
            font-weight: 500;
        }

        h3 {
            font-size: 24px;
            font-weight: 600;
        }

        label {
            font-size: 12px;
            font-weight: 400;
        }
    }

    .header-title.subtitle {
        font-size: 12px;
        font-weight: 600;
    }

    .repay-early-box {
        font-size: 9px;
        margin-top: 10px;

        .select-payment-method {
            font-size: 12px;
            padding: 10px;
            max-width: 150px;
            margin-right: 10px;
        }

        button {
            display: none;

            &.d-block {
                display: block;
            }
        }

        span {
            display: inline-block;
        }
    }

    .betting-frame {
        min-height: 200vh;
    }

    .vidoe-container {
        position: inherit;
    }

    .legal-frame {
        min-height: calc(100vh - 480px);

        &.mobile-pdf-reader{
            min-height: calc(100vh - 360px);
        }

        .mobile__pdf__container {
            #viewerContainer {
                top: 0;
                bottom: 40px;
            }
            footer {
                position: absolute;
                height: 40px;
            }
            #loadingBar {
                margin-top: 0;
            }

            .toolbarButton.pageUp{
                background-size: 1rem;
            }
            .toolbarButton.pageDown{
                background-size: 1rem;
            }
            .toolbarButton.zoomOut{
                background-size: 1rem;
            }
            .toolbarButton.zoomIn{
                background-size: 1rem;
            }
        }
    }

    .legal-modal-frame {
        min-height: calc(100vh - 160px);

        &.mobile-pdf-reader{
            min-height: calc(100vh - 160px);
        }

        .mobile__pdf__container {
            #viewerContainer {
                top: 0;
                bottom: 40px;
            }
            footer {
                position: absolute;
                height: 40px;
            }
            #loadingBar {
                margin-top: 0;
            }

            .toolbarButton.pageUp{
                background-size: 1rem;
            }
            .toolbarButton.pageDown{
                background-size: 1rem;
            }
            .toolbarButton.zoomOut{
                background-size: 1rem;
            }
            .toolbarButton.zoomIn{
                background-size: 1rem;
            }
        }
    }

    .tab-pane {
        &.pdf-container {
            display: block;
            position: absolute;
            visibility: hidden;
            width: 100%;

            &.active {
                position: relative;
                visibility: visible;
            }
        }
    }

    .pdf-container {
        iframe {
            display: none;
        }
    }

    .mobile-pdf-reader {
        display: block;
    }
}
