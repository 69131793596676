%secondary-button {
    text-align: center;
    background-color: #575757;
    border-color: #575757;
    color: #fff;
    border-radius: 3px;

    border: 1px solid transparent;
    padding: 0.45rem 0.9rem;
    font-size: 16px;
    line-height: 1.5;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    box-shadow: 0 2px 6px 0 #57575780;
    min-width: 120px;
    white-space: nowrap;

    &.disabled,
    &:disabled {
        opacity: 0.4;
    }
}

.payment-methods {
    button.payment-method-connect-plaid,
    button.add-new-payment-method,
    button.verification-microdeposit {
        @extend %secondary-button;
    }
}

.new-boost {
    button.check-balance {
        @extend %secondary-button;
    }

    button.check-balance {
        padding: 0.28rem 0.8rem;
        font-size: 0.875rem;
        line-height: 1.5;
        border-radius: 0.15rem;
    }
}

.repayment-boost {
    button.repay-early {
        @extend %secondary-button;
    }

    @media (max-width: 576px) {
        button.repay-early {
            font-size: 9px;
            font-weight: 600;
        }
    }
}

.cool-down-box {
    button.start-cooldown {
        @extend %secondary-button;
        margin-bottom: 0.75rem !important;
        margin-top: 0.75rem !important;
    }
}
