//
// _components-demo.scss
//

// Button
.button-list {
    margin-left: -8px;
    margin-bottom: -12px;

    .btn {
        margin-bottom: 12px;
        margin-left: 8px;
    }
}

// Scrollspy
.scrollspy-example {
    position: relative;
    height: 200px;
    margin-top: 0.5rem;
    overflow: auto;
}

// Grid
.grid-structure {
    .grid-container {
        background-color: $gray-100;
        margin-bottom: 10px;
        font-size: 0.8rem;
        font-weight: $font-weight-semibold;
        padding: 10px 20px;
    }
}

// Icons
.icons-list-demo {
    div {
        cursor: pointer;
        line-height: 45px;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
        overflow: hidden;

        p {
            margin-bottom: 0;
            line-height: inherit;
        }
    }

    i {
        text-align: center;
        vertical-align: middle;
        font-size: 22px;
        width: 50px;
        height: 50px;
        line-height: 50px;
        margin-right: 12px;
        border-radius: 3px;
        display: inline-block;
        transition: all 0.2s;
    }

    .col-md-4 {
        -webkit-border-radius: 3px;
        border-radius: 3px;
        -moz-border-radius: 3px;
        background-clip: padding-box;
        margin-bottom: 10px;

        &:hover,
        &:hover i {
            color: $primary;
        }
    }
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0;

    .card.view-card {
        color: #f8c761;
        background-color: #141414;
        border: 6px solid #61f866;
        border-radius: 10px;
        width: 512px;
        height: auto;
        margin-right: 20px;
        margin-bottom: 20px;
        position: relative;

        img {
            border: none;
            width: 100%;
            height: 100%;
            border-radius: 5px;
        }

        .card-logo {
            position: absolute;
            right: 20px;
            width: 80px;
            top: 20px;
        }

        .visa-logo {
            width: 40px;
            background: #fff;
            padding: 5px;
            border-radius: 4px;
            position: absolute;
            right: 15px;
            bottom: 15px;
        }

        .card-holder {
            position: absolute;
            bottom: 20px;
            left: 20px;
            margin-bottom: 0;
        }

        .card-info-line {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &.inactive {
            border-color: #fa5c7c;
        }

        .reveal-card-number {
            cursor: pointer;
            height: 40px;
            width: 100%;
            position: absolute;
            background: #000;
            top: 45%;
            letter-spacing: 2px;
            line-height: 38px;
            color: #f8c761;
            font-size: 15px;
            font-weight: 600;
            display: flex;
            justify-content: space-between;
            padding-left: 5px;

            .pan-separator {
                margin: 8px;
            }

            &.hidden {
                opacity: 0;
            }

            .numbers {
                filter: blur(3px);
            }

            .text {
                font-size: 16px;
                letter-spacing: initial;
                background: #000;
                padding: 0 4px;
                margin-right: auto;
            }

            @media (max-width: 768px) {
                .text {
                    font-size: 12px;
                }

                .numbers {
                    filter: blur(3px);
                    font-size: 12px;

                }
            }

        }
    }

    .card-number-wrap {
        display: flex;
        align-items: flex-start;

        .hidden-number {
            position: relative;
            height: 28px;
            line-height: 28px;

            &>label>span {
                filter: blur(3px);
            }

            &>button.btn-reveal {
                position: absolute;
                left: 24px;
                top: 0;
                padding: 0 5px;
                background: #0009;
                font-size: 14px;
                box-shadow: none;

                &:hover {
                    background: #f8c761;
                    color: #000;
                }
            }
        }

        button.btn-reverify {
            padding: 0;
            border: none;
            box-shadow: none;
            font-size: 20px;
            color: #f8c761;
            background: transparent;
            margin-left: 8px;
        }
    }
}

.card-bookmarkers {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h5 {
        margin-bottom: 20px;
        text-align: center;
    }

    h3 {
        color: #2ca9f2;
        text-align: center;
    }

    ul {
        display: grid;
        width: 100%;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        justify-content: center;
        align-items: center;
        padding: 0;

        li {
            list-style: none;
            display: inline-block;

            img {
                max-width: 150px;
                min-width: 75px;
                display: flex;
                flex: 1;
                margin: 20px auto;
            }
        }
    }

    @media (max-width: 768px) {
        ul {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
}

.sm-popover {
    .popover {
        border-color: #f8c761;
    }

    .popover-body {
        font-weight: bold;
    }
}

.btn-set-default {
    box-shadow: 0px 2px 6px 0px #0005 !important;
    background: #fff;
    display: none;
}

td.default-col {
    &:hover .btn-set-default {
        display: block;
    }

    i.method-default {
        font-size: 18px;
    }
}

.edge-toast.toast {
    position: fixed;
    top: 85px;
    z-index: 9999;
    right: 24px;
    max-width: 450px;
    min-width: 350px;
    background: #fff;

    &.success {
        background: #cef5ea;
    }

    &.danger {
        background: #fedee5;
    }

    &.warning {
        background: #f8c761;
    }
}

.alert.alert-warning {
    color: #000000;
    background-color: #f8c761;
    border-color: #f8c761;
}

label.number-copy {
    font-size: 18px;
    margin-left: 8px;
}

.rounded-pill.pill-primary {
    background: #141414 !important;
    color: #f8c761;
}

.rounded-pill.pill-secondary {
    color: #141414 !important;
    background: #f8c761;
}

.card-body {
    option.default-option {
        font-weight: bold;
    }
}

.p-carousel-item {
    max-width: 100%;
    height: 720px;
    background: white;
    position: relative;
}

.p-carousel-desc {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 150px;
    background: #f8c761dd;
    padding: 10px 40px;
    color: #000;
}

.p-carousel-image {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: top;
    background-size: contain;
}

.p-carousel-control {

    &.carousel-control-prev,
    &.carousel-control-next {
        top: 580px;
        width: 30px;
    }
}

.deposit-box {
    max-width: 900px;
}

// Ignore iubeda
.card-body a.ignore-iubeda,
.footer a.ignore-iubeda {
    font-family: inherit !important;
    border: none !important;
    border-color: transparent !important;
    font-size: 100% !important;
    font-weight: 400 !important;
    box-shadow: none !important;
    margin: 0 10px !important;
}

.legal-frame {
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 280px);
    border: none;
    position: relative;
}

.legal-modal-frame {
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 180px);
    border: none;
    position: relative;
}

.betting-frame {
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 280px);
    border: none;
}

.nav-tabs .nav-link {
    cursor: pointer;
}

.global-notifications {
    padding: 24px 12px 0;
}

table.tier-chart {
    tr.active {
        background-color: #f8c761 !important;
    }
}

.button-menu-mobile {
    color: #f8c761 !important;
}

.btn-default.disabled,
.btn-default:disabled,
.btn-default {
    color: #313a46;
    background-color: #f8c761;
    border-color: #f8c761;
    box-shadow: 0px 2px 6px 0px #ffbc0080;
}

.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default:not(:disabled):not(.disabled):active,
.btn-default:not(:disabled):not(.disabled).active {
    color: #313a46;
    background-color: #d9a000;
    border-color: #cc9600;
}

.btn-bold {
    font-weight: 600;
}

.agree-box {
    margin: 20px;
    text-align: left;
}

.agreement-text {
    font-size: 12px;
    margin-bottom: 10px;
}

.ssn-mask-wrapper {
    position: relative;
    width: 100%;
}

.ssn-switch {
    position: absolute;
    right: 18px;
    bottom: 8px;
    margin: 0;
    color: #000;
}

.password-mask-wrapper {
    position: relative;
    width: 100%;

    .text-danger {
        input {
            background-image: none;
        }
    }

    .password-switch {
        position: absolute;
        right: 12px;
        bottom: 4px;
        margin: 0;
        color: #000;
    }

    .rm-bg {
        background-image: none !important;
    }

    .text-danger+.password-switch {
        bottom: 26px;
        right: 12px;
    }

    @media (max-width: 768px) {
        .password-switch {
            bottom: 4px;
        }

        .text-danger+.password-switch {
            bottom: 26px;
        }
    }

    @media (max-width: 576px) {
        .password-switch {
            bottom: 10px;
        }

        .text-danger+.password-switch {
            bottom: 34px;
        }
    }
}

.x-hellosign-embedded--in-modal {
    z-index: 9999999999 !important;
}

.top-links {
    display: none;
}

.password-text {
    -webkit-text-security: disc;
}

.metabet-parlay-calculator {
    border: 1px solid #999;
    border-top: none;
}

.password-notice {
    margin: 0;
    padding-left: 15px;
}

.disable-overlap {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    background: #999a;
}

.btn-black {
    background: #000;
    border-color: #000;
    color: #f8c761;
    font-weight: 600;

    &:hover {
        background: #000;
    }
}

button {
    &.demo-make-new-boost {
        display: inline-block;
        text-align: center;
        vertical-align: middle;
        user-select: none;
        border: 1px solid transparent;
        padding: 0.28rem 0.8rem;
        font-size: 0.875rem;
        line-height: 1.5;
        border-radius: 0.15rem;
        margin-left: 0.75rem !important;
        background: #2ca9f2;
        border-color: #2ca9f2;
        color: #000;
        font-weight: 600;
    }

    &.new-boost-deposit {
        display: inline-block;
        text-align: center;
        vertical-align: middle;
        user-select: none;
        border: 1px solid transparent;
        padding: 0.28rem 0.8rem;
        font-size: 0.875rem;
        line-height: 1.5;
        border-radius: 0.15rem;
        font-weight: 600;

        &.disabled,
        &:disabled {
            opacity: 0.65;
            color: #313a46;
            background-color: #f8c761; // Background color when disabled
            border-color: #f8c761; // Border color when disabled
            box-shadow: 0px 2px 6px 0px #ffbc0080; // Original box-shadow
        }

        color: #fefefe;
        background-color: blue; // Default background color when enabled
        border-color: blue; // Default border color when enabled
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 255, 0.5); // Adjusted box-shadow for blue
    
    }
}

@media (min-width: 576px) {
    .d-sm-cell {
        display: table-cell !important;
    }
}

// Dropdown Large (Custom)
@media (max-width: 576px) {
    .center-title {
        font-size: 1rem;
    }
}

@media (max-width: 374px) {
    button {
        &.demo-make-new-boost {
            display: inline-block;
            text-align: center;
            vertical-align: middle;
            user-select: none;
            border: 1px solid transparent;
            margin-left: 0 !important;
            padding: 0.28rem 0.8rem;
            font-size: 0.875rem;
            line-height: 1.5;
            border-radius: 0.15rem;
            background: #2ca9f2;
            border-color: #2ca9f2;
            color: #000;
            font-weight: 600;
        }
    }
}

// Loading CSS
@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 64px;
        height: 64px;
        margin: 8px;
        border: 8px solid #fff;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #fff transparent transparent transparent;

        &:nth-child(1) {
            animation-delay: -0.45s;
        }

        &:nth-child(2) {
            animation-delay: -0.3s;
        }

        &:nth-child(3) {
            animation-delay: -0.15s;
        }
    }
}