body.register-wizard {
    .wizard-wrapper {
        .navbar-custom {
            .back-link {
                display: none;
            }
        }
        .wizard-siebar {
            width: 260px;
            z-index: 10;
            background: #141414;
            bottom: 0;
            position: fixed;
            top: 0;
            padding-top: 90px;
            box-shadow: 0 0 35px 0 #9aa1ab26;

            .logo-lg {
                display: block;
                padding-left: 30px;
                padding-right: 30px;
                position: fixed;
                line-height: 70px;
                width: 260px;
                top: 0;
                img {
                    width: 100%;
                    height: auto;
                }
            }

            ul {
                padding: 0;
                margin: 0;
                li {
                    padding-left: 25px;
                    margin-bottom: 20px;
                    font-size: 15px;
                    line-height: 24px;
                    width: 100%;
                    position: relative;
                    list-style-type: none;
                    border-left: 3px solid transparent;
                    color: #f8c761;

                    &.active {
                        border-left: 3px solid #f8c761;
                    }

                    i {
                        position: absolute;
                        right: 25px;
                        top: 0;
                        border-radius: 50%;
                        width: 24px;
                        height: 24px;
                        display: block;
                        background-color: #f8c761;
                        color: black;
                        text-align: center;
                        line-height: 20px;
                        &:before {
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }

    .wizard-container {
        margin-left: 260px;
        overflow: hidden;
        padding: 120px 0 65px;
        min-height: 100vh;
        background: #fafbfe;

        .wizard-content {
            overflow: auto;
            padding: 20px;

            h1 {
                margin: 0;
                margin-bottom: 80px;
            }

            & > .alert {
                display: block;
            }

            .content-box {
                min-height: 120px;
                position: relative;
                background-color: #fff;
                background-clip: initial;
                border: 1px solid #eef2f7;
                border-radius: 3px;
                padding: 24px;
                box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
                font-size: 16px;

                & > .alert {
                    display: block;
                }

                .account-header {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    justify-content: space-between;
                }

                .wizard-form {
                    // min-height: 120px;
                    // position: relative;

                    .alert {
                        display: none;
                    }

                    .button-box {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;

                        & > a.btn:not(.next) {
                            display: block;
                        }
                    }

                    .document-box {
                        border: 1px solid #000;
                        border-radius: 3px;
                        padding: 0 40px 30px 40px;
                        margin-bottom: 40px;
                        h2 {
                            margin-bottom: 32px;
                        }
                    }

                    .fade-progress {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        background: #fffd;
                        text-align: center;
                        padding: 20px;
                        font-weight: 600;
                    }

                    .bank-account-box,
                    .payment-method-box {
                        border: 1px solid #000;
                        border-radius: 3px;
                        padding: 20px;
                        margin-bottom: 20px;
                        h2 {
                            font-size: 16px;
                            margin-bottom: 12px;
                        }
                    }

                    .bank-account-box {
                        border: 1px dotted #2ca9f2;
                    }

                    .table {
                        font-size: 16px;
                        thead {
                            th {
                                border: none;
                            }
                        }
                        th,
                        td {
                            border: none;
                            padding: 5px 8px;
                            &:first-child {
                                padding-left: 0;
                            }
                            &:last-child {
                                padding-right: 0;
                            }
                        }
                    }

                    .payment-method-box {
                        .table {
                            font-size: 14px;
                        }
                    }

                    .account-logo-box {
                        display: flex;
                        justify-content: center; // Center the items
                        align-items: center;
                        margin-bottom: 20px;
                        gap: 10px; 
                        img {
                            margin: auto; 
                            align-self: center; // Align the logo vertically
                            padding: 10px 0;
                            max-width: 150px; // Optional: limit the maximum width
                        }
                    
                        @media (max-width: 600px) { // Example of a media query for smaller screens
                            flex-direction: column; // Stack logos vertically on small screens
                            gap: 10px;
                        }

                        @media (min-width: 601px) {
                            // Styles for desktop screens
                            gap: 30px; // Increase gap for desktop view
                        }

                    }
                    
                }

                .status-box {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    label {
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 20px;
                    }
                    .status-text {
                        border-radius: 30px;
                        border: 1px solid #000;
                        padding: 5px 20px;
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 18px;
                        margin-bottom: 0;
                        text-transform: capitalize;
                        background-color: #2ca9f2;

                        &.passed {
                            background-color: #f8c761;
                        }
                    }
                }

                .verify-cell {
                    width: 210px !important;
                }
            }
        }
    }

    @media (max-width: 767px) {
        .wizard-wrapper {
            .wizard-siebar {
                display: none;
            }

            .navbar-custom {
                .back-link {
                    display: block;
                    position: absolute;
                    color: #f8c761;
                    left: 0;
                    width: 40px;
                    text-align: center;
                    font-size: 24px;
                    font-weight: bold;
                    line-height: 70px;

                    &.disabled {
                        color: #aaa;
                        display: none;
                    }
                }
            }
        }
        .wizard-container {
            margin-left: 0;
            padding: 120px 0 0;
            background: #fff;
            .wizard-content {
                padding: 20px 0 0 0;
                & > .alert {
                    display: none;
                }

                .content-box {
                    font-size: 14px;
                    padding: 16px;
                    box-shadow: none;
                    border-color: transparent;
                    min-height: calc(100vh - 90px);

                    & > .alert {
                        display: none;
                    }

                    .account-header {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: space-between;

                        & > .d-flex {
                            width: 100%;
                        }
                    }

                    .wizard-form {
                        // min-height: calc(100vh - 160px);
                        // padding-bottom: 80px;
                        &.verify-form {
                            padding-bottom: 40px;
                        }

                        &.bank-account-form {
                            min-height: calc(100vh - 240px);
                        }

                        &.payment-method-form {
                            min-height: calc(100vh - 215px);
                        }

                        .alert {
                            display: block;
                        }

                        .button-box,
                        & + .button-box {
                            // position: absolute;
                            // bottom: 0;
                            & > a.btn:not(.next) {
                                display: none;
                            }
                        }

                        & + .button-box {
                            bottom: 18px;
                            left: 18px;
                            right: 18px;
                        }

                        .document-box {
                            padding: 0 20px 15px 20px;
                            margin-bottom: 20px;
                            h2 {
                                margin-bottom: 16px;
                            }
                        }
                        .table {
                            font-size: 14px;
                        }

                        .account-logo-box{
                            img{
                                height: 60px;
                            }
                        }
                    }

                    .button-box {
                        flex-direction: column;
                        align-items: center;

                        .status-box {
                            margin-bottom: 20px;
                        }

                        .btn {
                            margin-bottom: 18px;
                            height: 50px;
                            border-radius: 30px;
                            font-size: 18px;
                        }
                    }
                }
            }
        }

        #hubspot-messages-iframe-container {
            display: none !important;
        }
    }
}
.suggestionItem:hover {
    cursor: pointer;
    background-color: lightgray;
}