body.register-wizard {
    overflow: auto;
    background-color: #fff;
    color: #000;
    min-height: 100vh;

    h1 {
        font-size: 36px;
        line-height: 42px;
        font-weight: 500;
        margin: 32px 0 48px 0;
    }

    h2 {
        font-size: 18px;
        line-height: 28px;
        font-weight: 600;
        margin: 0 0 16px 0;
    }

    .mt-60 {
        margin-top: 60px !important;
    }

    .top-links {
        display: block;
        position: absolute;
        right: 60px;
        top: 20px;
        font-size: 18px;
        font-weight: 500;
        a {
            color: #000;
        }
    }

    .form-group {
        margin-bottom: 16px;
        label {
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            margin-bottom: 8px;
        }
    }
    .form-control {
        font-size: 16px;
        line-height: 18px;
        font-weight: 400;
        padding: 8px 16px;
        border-radius: 5px;
        background-color: #fff;
        border: 1px solid #dee2e6;
        height: 38px;

        &.is-invalid {
            border-color: #fa5c7c;
            background: transparent;
        }

        &:disabled,
        &[readonly] {
            background-color: #d6d6d6;
            border-color: #d6d6d6;
        }
    }

    select.form-control:not([size]):not([multiple]) {
        height: 38px;
    }

    .invalid-feedback {
        font-size: 14px;
        margin-top: 5px;
    }

    .btn {
        background-color: #575757;
        border-color: #575757;
        color: #fff;
        border-radius: 3px;
        box-shadow: none;

        border: 1px solid transparent;
        padding: 0.45rem 0.9rem;
        font-size: 16px;
        line-height: 1.5;
        border-radius: 0.15rem;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
            box-shadow 0.15s ease-in-out;
        box-shadow: 0 2px 6px 0 #57575780;
        min-width: 120px;
        font-weight: 600;

        &.btn-success {
            background-color: #33c830;
            border-color: #33c830;
        }

        &.btn-info {
            background-color: #0ab6cd;
            border-color: #0ab6cd;
        }

        &.btn-warning {
            background-color: #f8c761;
            border-color: #f8c761;
            color: #000;
        }

        &.btn-primary {
            background-color: #009EF7;
            border-color: #009EF7;
            color: #fff;            
        }

        &.btn-sm {
            font-size: 16px;
            line-height: 48px;
            min-width: 120px;
            height: 48px;
        }
        &.disabled,
        &:disabled {
            opacity: 0.4;
        }

        &.btn-link {
            min-width: 36px;
            background-color: transparent;
            border: 1px solid transparent;
            color: #000;
            padding: 5px 5px;
            font-size: 16px;
            font-weight: 600;
            max-height: 28px;
            line-height: 16px;
            width: auto;
            &:hover {
                border-color: #000;
            }
        }

        &.btn-verify {
            font-size: 12px;
            min-width: 60px;
            padding: 5px 10px;
            min-height: 32px;
            height: auto;
            font-weight: 600;
            line-height: 16px;
            width: auto;
        }
    }

    .ssn-mask-wrapper {
        position: relative;
        width: 100%;
        .text-danger + .ssn-switch {
            bottom: 30px;
            right: 32px;
        }
    }

    .ssn-switch {
        position: absolute;
        right: 12px;
        bottom: 4px;
        margin: 0;
        color: #000;
    }

    .stripe-form {
        #card-element {
            box-shadow: none;
            border-radius: 5px;
            color: #000;
        }
    }

    .form-check {
        padding-left: 32px;
        font-size: 16px;
        line-height: 24px;
        height: 24px;
        margin-bottom: 12px;
        .form-check-input {
            margin-top: 0;
            margin-left: -32px;
            height: 24px;
            width: 24px;
        }
    }

    .footer {
        left: 0;
    }

    .modal-dialog {
        font-size: 16px;

        .modal-title {
            font-size: 18px;
        }

        .modal-content {
            background-color: #fff;
        }

        .modal-body,
        .modal-header,
        .modal-footer {
            padding: 10px 20px;
        }
    }

    .text-sm {
        font-size: 14px !important;
    }

    .wizard-mobile-siebar-wrapper {
        display: none;
        margin: 0;
        padding: 0;
        width: 100%;
        position: fixed;
        z-index: 1000;

        .progress-percent {
            position: absolute;
            left: 0;
            right: 0;
            text-align: center;
            font-size: 14px;
            line-height: 20px;
            font-weight: bold;
        }
    }

    .wizard-mobile-siebar {
        margin: 0;
        padding: 0;
        width: 100%;
        list-style: none;
        display: flex;
        li {
            width: 100%;
            height: 20px;
            background-color: #ddd;
            &.passed {
                background-color: #f8e2b6;
            }
            &.active {
                background-color: #f8c761;
            }
        }
    }

    .status-text {
        border-radius: 30px;
        border: 1px solid #000;
        padding: 2px 10px;
        font-weight: 400;
        margin-bottom: 0;
        text-transform: capitalize;
        background-color: #2ca9f2;

        &.passed {
            background-color: #f8c761;
        }
    }

    @media (max-width: 767px) {
        h2 {
            font-size: 16px;
            line-height: 24px;
            font-weight: 600;
            margin: 0 0 12px 0;
        }
        .form-group {
            label {
                font-size: 14px;
                line-height: 20px;
                font-weight: 500;
                margin-bottom: 8px;
            }
        }

        .form-control {
            font-size: 16px;
            line-height: 18px;
            font-weight: 400;
            padding: 8px 20px;
            border-radius: 30px;
            height: 50px;
        }

        select.form-control:not([size]):not([multiple]) {
            height: 50px;
        }

        .invalid-feedback {
            font-size: 12px;
        }
        .btn {
            padding: 0.45rem 0.9rem;
            min-width: 100px;
            width: 100%;
            border-radius: 30px;
            font-size: 18px;
        }

        .wizard-mobile-siebar-wrapper {
            display: block;
        }

        .ssn-switch{
            bottom: 10px;
        }
    }
}


$offset: 187;
$duration: 1.4s;

.spinner {
  animation: rotator $duration linear infinite;
}

@keyframes rotator {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(270deg); }
}

.path {
  stroke-dasharray: $offset;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation:
    dash $duration ease-in-out infinite, 
    colors ($duration*4) ease-in-out infinite;
}

@keyframes colors {
  0% { stroke: #4285F4; }
  25% { stroke: #F7C223; }
  50% { stroke: #4285F4; }
  75% { stroke: #F7C223; }
  100% { stroke: #4285F4; }
}

@keyframes dash {
 0% { stroke-dashoffset: $offset; }
 50% {
   stroke-dashoffset: $offset*0.25;
   transform:rotate(135deg);
 }
 100% {
   stroke-dashoffset: $offset;
   transform:rotate(450deg);
 }
}