//
// tables.scss
//

//Table centered (Custom)
.table-centered {
    th,
    td {
        vertical-align: middle !important;
    }
}

// Custom table components (Custom)
.table {
    .table-user {
        img {
            height: 30px;
            width: 30px;
        }
    }
    .action-icon {
        color: $gray-600;
        font-size: 1.2rem;
        display: inline-block;
        padding: 0 3px;

        &:hover {
            color: $gray-700;
        }
    }
}

// Tables fluid
.table-nowrap {
    th,
    td {
        white-space: nowrap;
    }
}

.table{
    th,td{
        &.success{
            color: #23c52c;
            font-weight: 600;
        }
        &.failed{
            color: $danger;
            font-weight: 600;
        }
        &.pending{
            color: #0136E5;
            font-weight: 600;
        }
    }
}

///////////////////////////////////////////////////

@media (max-width: 576px) {
    table.responsive-table {
        border: 0;

        caption {
            font-size: 1.3em;
        }

        thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        tr {
            border: 1px solid #ddd;
            border-radius: 4px;
            padding: 0 10px;
            display: block;
            margin-bottom: 15px;
            &:last-child {
                margin-bottom: 0;
            }
        }

        td {
            border: none;
            display: block;
            font-size: 14px;
            text-align: right;
            padding: 10px 0;
            min-height: 40px;

            &.default-col:hover .btn-set-default {
                display: inline-block;
            }
        }

        td::before {
            /*
        * aria-label has no advantage, it won't be read inside a table
        content: attr(aria-label);
        */
            content: attr(datalabel);
            float: left;
            font-weight: bold;
        }

        td:last-child {
            border-bottom: 0;
        }

        .btn.btn-set-default {
            display: inline-block;
            color: #fff;
            box-shadow: none !important;
            border: 1px solid #ddd;
            border-radius: 4px;
            padding: 0 4px;
        }
    }

    table.list-table {
        font-size: 9px;
        th,
        td {
            padding: 0.5rem 0.3rem;
            word-break: break-word;
            &:first-child {
                padding-left: 0.8rem;
            }
            &:last-child {
                padding-right: 0.8rem;
            }

            .badge {
                font-size: 9px;
            }

            .btn {
                font-size: 9px;
                padding: 0.3rem;
            }

            .form-check-input{
                position: relative;
                margin: 0;
            }
        }
    }
}
