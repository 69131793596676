body.register-wizard {
    .register-account {
        padding: 90px 0;
        max-width: 540px;
        margin: auto;

        font-size: 18px;

        .form-control {
            background-color: #e2e2e2;
        }
        .form-group {
            margin-bottom: 32px;
        }

        .btn{
            font-size: 24px;
            line-height: 28px;
            width: 100%;
            height: 60px;
            background-color: #4B4B4B;
            color: #fff;
            margin-top: 40px;
            border-radius: 5px;
            box-shadow: none;
        }

        a{
            color: #000;
        }
    }
}
